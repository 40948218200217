<template>
  <div>
    <div class="header">
      <div class="headRight">
        <a @click="goBack"><i class="el-icon-arrow-left"></i></a>
      </div>
      <div class="headLeft">提交完成</div>
    </div>
    <div class="main" style="margin-bottom: 5%">
      <div class="mainTop">
        <p><i class="el-icon-warning-outline" style="font-size: 100px"></i></p>
        <p style="margin-top: 3%; margin-bottom: 1%">
          <span style="font-size: 15px"
            >本次共提交<span v-text="text"></span>条更改</span
          >
        </p>
        <p>
          <span style="font-size: 15px"
            >其中<span v-text="textOne"></span>条成功，<span
              v-text="textTwo"
            ></span
            >条失败</span
          >
        </p>
      </div>
      <div class="mainBottom">
        <p style="margin-bottom: 3%">
          <span style="font-size: 15px"
            >失败的<span v-text="textTwo"></span>条为：</span
          >
        </p>
        <p>
          <span style="font-size: 13px; font-weight: 400">IMEI不存在:</span>
          <el-input
            :disabled="true"
            type="textarea"
            v-model="textarea"
            style="margin-top: 1%; margin-bottom: 1%; color: #000"
          ></el-input>
          <span style="font-size: 13px; font-weight: 400">已绑定:</span>
          <el-input
            :disabled="true"
            type="textarea"
            v-model="bindtext"
            style="margin-top: 1%; color: #000"
          ></el-input>
        </p>
      </div>
      <div style="text-align: center">
        <el-button
          type="warning"
          @click="warningBtn"
          style="padding: 10px 32px; margin-top: 15%"
          >处理异常</el-button
        >
        <el-button
          type="danger"
          @click="dangerBtn"
          style="padding: 10px 32px; margin-top: 15%"
          >放弃操作</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      text: null,
      textOne: null,
      textTwo: null,
      textarea: null,
      bindtext: null,
      groupIds: null,
      statusOne: "",
      allString: "",
      binString: "",
      hisNoTwo: null,
    };
  },
  mounted() {
    let dataSubmit = JSON.parse(localStorage.getItem("dataList"));
    console.log(dataSubmit, "传过来的数据");
    this.textTwo = dataSubmit.fail;
    this.textOne = dataSubmit.success;
    this.text = dataSubmit.total;
    this.textarea = dataSubmit.failList;
    this.bindtext = dataSubmit.bindList;
    this.groupIds = dataSubmit.groupId;
    this.statusOne = dataSubmit.status;
    this.hisNoTwo = dataSubmit.hisNo;
    console.log(this.textarea, "this.textarea");
    for (let i = 0; i < this.textarea.length; i++) {
      this.allString += this.textarea[i] + "\n";
      console.log(this.allString, "查看");
    }
    this.textarea = this.allString;
    for (let i = 0; i < this.bindtext.length; i++) {
      this.binString += this.bindtext[i] + "\n";
      console.log(this.binString, "查看");
    }
    this.bindtext = this.binString;
  },
  methods: {
    //返回上一级
    goBack() {
      window.location.href = "./#/camera";
      window.localStorage.removeItem("alltring");
    },
    warningBtn() {
      this.$confirm("是否需要继续处理异常数据！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(this.bindtext, "this.bindtext");
          if (this.bindtext.length === 0) {
            this.$message({
              showClose: true,
              message: "暂无需要处理异常数据",
              type: "warning",
            });
            this.$router.push("./camera");
            window.localStorage.removeItem("alltring");
            window.localStorage.setItem("dataLisTwo", this.textarea);
            window.localStorage.removeItem("dataList");
            window.localStorage.removeItem("alltringTwo");
          } else {
            this.bindtext = this.bindtext.split("\n").filter(Boolean);

            let num = 0;
            console.log(this.bindtext.length, "查看数据");
            var resData = [];
            for (var i = 0, len = this.bindtext.length; i < len; i += 20) {
              resData.push(this.bindtext.slice(i, i + 20));
            }
            resData.forEach((item) => {
              let obj = {
                groupId: this.groupIds,
                imeis: item,
                status: this.statusOne,
                hisNo: this.hisNoTwo,
              };
              console.log(obj, "查看传的数据");
              apiShout.updateBindGroups(obj).then((res) => {
                if (res.data.code === 200) {
                  num++;
                  console.log(num, resData.length, "123resData");
                  if (num == resData.length) {
                    this.$message({
                      type: "success",
                      message: "处理异常数据成功!",
                    });
                    this.$router.push("./camera");
                    window.localStorage.removeItem("alltring");
                    window.localStorage.setItem("dataLisTwo", this.textarea);
                    window.localStorage.removeItem("dataList");
                    window.localStorage.removeItem("alltringTwo");
                  }
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            });
          }
        })
        .catch(() => {});
    },
    dangerBtn() {
      this.$confirm("确定放弃操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("./camera");
          window.localStorage.removeItem("alltring");
          window.localStorage.removeItem("dataList");
          window.localStorage.removeItem("alltringTwo");
          window.localStorage.removeItem("dataLisTwo");
          this.$message({
            type: "success",
            message: "已放弃操作!",
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
body {
  position: inherit !important;
}
.el-message-box {
  width: 80% !important;
}
</style>
<style lang='less' scoped>
.header {
  width: 100%;
  line-height: 40px;
  background: rgba(242, 242, 242, 1);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: fixed;
}
.headRight {
  width: 6%;
  font-size: 26px;
  line-height: 40px;
}
.headLeft {
  width: 90%;
  text-align: center;
  font-size: 15px;
}
.main {
  width: 80%;
  margin-left: 10%;
  padding-top: 23%;
}
.mainTop {
  text-align: center;
  margin-bottom: 15%;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #000;
}
/deep/ .el-textarea__inner {
  min-height: 230px !important;
}
</style>